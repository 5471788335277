import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ImageCard from '../../UI/Card/ImageCard'
import ContentContainer from '../../UI/ContentContainer'

import PrevIcon from '../../../assets/images/svg/pl-carousel-prev-icon.svg'
import NextIcon from '../../../assets/images/svg/pl-carousel-next-icon.svg'

const Carouselv2 = ({ page }) => {
  const { cardsHeader, cardsSubheader, cardsContent } = page

  const [xAxis, setXAxis] = useState(0)

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const cards = document.querySelectorAll('.card')
      cards.forEach(
        (c) => (c.style.transform = `translate3d(${xAxis}px, 0px, 0px)`)
      )
    } else {
      return
    }
  }, [xAxis])

  // Change the value here to make the card smaller or wider
  const cardWidth = 399
  const containerMaxWidth = (cardsContent.length * cardWidth) + cardWidth

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <ContentContainer background="var(--base-gray)">
        <CarouselWrapper>
          <div className="slider-text-wrap">
            <div>
              <h2>{ cardsHeader }</h2>
              <p className="large-text">
                { cardsSubheader }
              </p>
            </div>
            <div className="btn-wrap">
              <button
                onClick={() => setXAxis(xAxis + cardWidth)}
                disabled={xAxis >= 0}
              >
                <PrevIcon />
              </button>
              <button
                onClick={() => setXAxis(xAxis - cardWidth)}
                disabled={xAxis <= (-1 * cardWidth) * (cardsContent.length - 1)}
              >
                <NextIcon />
              </button>
            </div>
          </div>

          <SwiperWrapper containerMaxWidth={containerMaxWidth}>
            <div className="swiper">
              <div className="swiper-items">
                {cardsContent.map((item, index) => (
                  <a className="card" href={item.linkOnClick} key={index}>
                    <ImageCard
                      imgPath={item.image?.gatsbyImageData}
                      imgAlt={item.image.title}
                    >
                      <h3>{item.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.childMarkdownRemark.html,
                        }}
                      />
                    </ImageCard>
                  </a>
                ))}
              </div>
            </div>
          </SwiperWrapper>
        </CarouselWrapper>
      </ContentContainer>
    </div>
  )
}

const CarouselWrapper = styled.div`
  display: flex;
  h2 {
    margin-bottom: 24px;
    font-size: 32px;
  }
  p {
    margin: 0;
  }
  .slider-text-wrap {
    display: flex;
    width: 289px;
    min-width: 250px;
    margin-right: 32px;
    flex-direction: column;
    justify-content: space-between;
  }
  .btn-wrap {
    display: flex;
    flex-direction: row;
  }
  button {
    z-index: 11;
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #264b65;
    cursor: pointer;
  }
  button:disabled {
    background-color: rgba(38, 75, 101, 0.4);
    cursor: default;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    h2 {
      margin-bottom: 16px;
    }
    .slider-text-wrap {
      width: 100%;
      margin-bottom: 32px;
    }
    .btn-wrap {
      display: none;
    }
  }
`

const SwiperWrapper = styled.div`
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  position: relative;
  width: 100%;
  max-width: ${props => props.containerMaxWidth || 5000}px;
  margin-right: auto;
  margin-left: auto;

  .swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .swiper-items::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  .swiper-items {
    display: flex;
    cursor: grab;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    a.card {
      display: flex;
      flex-grow: 1;
      -webkit-transform-style: preserve-3d;
      transition: 300ms;
      margin-right: 32px;
      :hover {
        color: inherit;
      }
      > div {
        margin: 0;
        box-shadow: none;
        position: relative;
        min-width: 367px;
        border-radius: 16px;
        padding: 16px 16px 24px;
      }
    }
    .imgWrapper {
      border-radius: 16px;
      margin-bottom: 16px;
    }
    .desc {
      padding: 0;
    }
    h3 {
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      margin: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .swiper-items p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 479px) {
    .card {
      margin-right: 16px !important;
      > div {
        min-width: 260px !important;
      }
    }
  }
`

export default Carouselv2
